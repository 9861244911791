<template>
  <v-container class="">
    <LogoHeader></LogoHeader>
    <v-row class="justify-center d-flex mt-2">
      <v-col cols="10" lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="d-flex">
            <v-img
              src="@/assets/img/verify_email.png"
              class="mt-2"
              width="100px"
              height="100px"
              :contain="true"
            ></v-img>
          </div>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
              <v-icon size="27px" :style="'transform: translateY(-2px);'" >far fa-check-circle</v-icon> Zweryfikuj adres email
            </h5>
            <v-alert dense class="bg-gradient-info mt-2">
              <span :style="'color: white'">
                <v-icon :style="'color: white'">fas fa-info-circle</v-icon> Na
                podany przez ciebie adres email został przesłany link do
                zweryfikowania adresu email. Jeżeli nie możesz znaleźć wiadomości sprawdź folderach Spam lub Oferty.
              </span>
            </v-alert>
            <p class="text-sm text-body mt-3 mb-0">
              Wiadomość nie dotarła?
            </p>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-1
                mb-2
                w-100
              "
              color="#5e72e4"
              @click="$refs.captcha.execute()"
              :loading="sendAgainLoading"
              ><v-icon>fas fa-envelope</v-icon>
              <span class="ml-1">Wyślij ponownie</span>
            </v-btn>
            <vue-recaptcha
              ref="captcha"
              :sitekey="$captchaSitekey"
              @verify="sendAgain"
              :loadRecaptchaScript="true"
            >
            <button style="display: none"></button>
            </vue-recaptcha>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <Footer></Footer>
  </v-container>
</template>

<script>
import qs from "qs";
import VueRecaptcha from "vue-recaptcha";
import LogoHeader from "@/components/util/LogoHeader.vue"
import Footer from "@/components/Footer.vue";
export default {
  name: "VerifyEmail",
  data() {
    return {
      sendAgainLoading: false,
    };
  },
  components:{
    VueRecaptcha,
    LogoHeader,
    Footer
  },
  methods: {
    async sendAgain(captcha_token) {
      this.$refs.captcha.reset();
      this.sendAgainLoading = true;
      try {
        await this.$axios({
          method: "post",
          url: "/api/auth/sendverify",
          data: qs.stringify({
            captcha: captcha_token,
            email: this.email,
          }),
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        this.$toast.open({
          message: `<span class="font-weight-bold">Wysłano link na email !</span>`,
          type: "success",
          duration: 3000,
          position: "bottom",
        });
        this.sendAgainLoading = false;
      } catch (error) {
        this.sendAgainLoading = false;
      }
    }
  }
  
};
</script>

<style></style>
